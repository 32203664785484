import React, { useEffect, useState } from "react";
import {
  CustomCheckBoxCircular,
  CustomText,
  Image,
  PrimaryCta,
} from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import SideMenu from "../../screens/sideMenu";
import Header from "../../screens/header";
import "react-best-tabs/dist/index.css";
import { useDispatch } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import CampaignDetailsScreen from "./campaignDetailsScreen";
import BidListScreen from "./bidListScreen";
import AssignmentListScreen from "./assignmentListScreen";
import EditCampaignDetailsScreen from "../editCampaign/editCampaignDetailsScreen";
import { useSelector } from "react-redux";
import Icon from "../../components/core_component/icon";
import Core from "../../common/clientSdk";
import Toggle from "react-toggle";
import { Loader } from "../loader";
import InviteList from "./inviteList";
import ContentList from "./contentList";
import AnalyticsCampaign from "./analyticsCampaign";
import { toast } from "react-toastify";
import Modal from "react-modal";
import * as Actions from "../../redux/actions";
import CollectionInfluencerCampaignList from "./collectionInfluencerCampaign";
function CampaignDetailsParentScreen(props) {
  const [campaignDetail, setCampaignDetail] = useState({});

  const [activeTabPage, setActiveTabPage] = useState("");
  const [activeTab, setActiveTab] = useState("proposal-page");

  const [published, setPublished] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [bidId, setBidId] = useState();
  const [bidValue, setBidValue] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [searchName, setSearchName] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [filterLabel, setFilterLabel] = useState("");
  const [bidListSort, setBidListSort] = useState([]);
  const [bidList, setBidList] = useState([]);
  const [influencerList, setInfluencerList] = useState(null);
  const [influencerInviteList, setInfluencerInviteList] = useState([]);
  const [totalRows, setTotalRows] = useState("");
  const [btnNameReact1, setBtnNameReact1] = useState("Set Offline");
  const [btnNameReact2, setBtnNameReact2] = useState("Unboost Campaign");
  const [loading, setIsLoading] = useState({ boost: false, offline: false });
  const [isBoosted, setIsBoosted] = useState(null);
  const [isOffline, setIsOffline] = useState(null);
  const [isComplete, setIsComplete] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [marked, setMarked] = useState(false);
  const [isActiveModal, setIsActiveModal] = useState(null);
  const [isActiveModalText, setIsActiveModalText] = useState("");

  const [filterData, setFilterData] = useState({
    gender: null,
    city: [],
    state: [],
    follower: [],
    category: [],
    profession: [],
    counter: null,
    invite_mark: null,
  });
  const [isInviteLoading, setInviteLoading] = useState({});
  const [modalCampaignDetailsIsOpen, setModalCampaignDetailsIsOpen] =
    useState(false);

  const campaignId = useParams().id;

  var redux_dispatch = useDispatch();
  useEffect(() => {
    getCampaignUserDetail(updateProgress);
    // getBidList();
  }, []);

  console.log("API_URN_UPDATE_CAMPAIGN_ADMIN", campaignDetail);

  function updateProgress(status) {
    setLoading(status);
  }
  function updateProgress_(status) {}

  function getBidList() {
    var params = {
      campaign_id: campaignId,
    };
    if (searchName != null) {
      params.search = searchName;
    }
    if (sortBy != null) {
      params.sort_by = sortBy;
    }
    if (sortOrder != null) {
      params.sort_order = sortOrder;
    }

    Core.getBidList(
      getBidListSuccess,
      getBidListFailed,
      updateProgress,
      params
    );
  }

  function getBidListSuccess(response_data) {
    setBidListSort(response_data["bid_list"]);
  }

  function getBidListFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  function getCampaignUserDetail(progress_cb) {
    const params = {
      // brand_id: brand_data.brand_id,
      campaign_id: campaignId,
    };
    Core.getCampaignUserDetail(
      getCampaignUserDetailSuccess,
      getCampaignUserDetailFailed,
      progress_cb,
      params
    );
  }

  function getCampaignUserDetailSuccess(response_data) {
    setCampaignDetail(response_data.campaign_data);
    setLoading(false);
  }

  function getCampaignUserDetailFailed(errorList) {
    // openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function postPublishedCampaign() {
    const params = {
      // brand_id: brand_data.brand_id,
      campaign_id: campaignId,
    };
    Core.postPublishedCampaign(
      postPublishedCampaignSuccess,
      postPublishedCampaignFailed,
      updateProgress,
      params
    );
  }

  function postPublishedCampaignSuccess(response_data) {
    getCampaignUserDetail(updateProgress_);
  }

  function postPublishedCampaignFailed(errorList) {
    // openSnackbar(errorList.map((item) => item.m).join(", "));
  }
  function postUnPublishedCampaign() {
    const params = {
      // brand_id: brand_data.brand_id,
      campaign_id: campaignId,
    };
    Core.postUnPublishedCampaign(
      postUnPublishedCampaignSuccess,
      postUnPublishedCampaignFailed,
      updateProgress,
      params
    );
  }

  function postUnPublishedCampaignSuccess(response_data) {
    getCampaignUserDetail();
  }

  function postUnPublishedCampaignFailed(errorList) {
    // openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function getInfluencerList(bidList, page) {
    let newPage = 0;
    if (page == 10) {
      newPage = 0 * Number(page);
    } else {
      newPage = 50 * Number(page) - 50;
    }

    let filter_data = {
      location: filterData.state,
      gender: filterData.gender,
      profession: filterData.profession,
      is_invited: filterData?.invite_mark?.toString(),
    };
    var params = {
      campaign_id: campaignId,
      limit: 50,
      start: isNaN(newPage) ? 0 : newPage,
      filter_data: JSON.stringify(filter_data),
    };

    if (sortBy != null) {
      params.sort_by = sortBy;
    }
    if (sortOrder != null) {
      params.sort_order = sortOrder;
    }
    Core.getInfluencerList(
      (...args) => getInfluencerListSuccess(bidList, ...args),
      getInfluencerListFailed,
      updateProgress,
      params
    );
  }

  function getInfluencerListSuccess(bidList, response_data) {
    if (bidList) {
      let influencerId =
        bidList && bidList?.map((item) => item.bid_data.influencer_id);
      if (response_data.invite_list) {
        const updateList = response_data.invite_list.filter(
          (item) => !influencerId?.includes(item.influencer_data?.influencer_id)
        );
        setInfluencerList(updateList);
      }
    }

    setLoading(false);
    setTotalRows(response_data.total_count);
  }

  function getInfluencerListFailed(errorList) {
    toast.error(errorList);
    setInfluencerList([]);
    setLoading(false);
  }

  const handlePageChange = (page) => {
    getInfluencerList(bidList, page);
  };

  function getInfluencerInviteList() {
    var params = {
      campaign_id: campaignId,
    };
    Core.getInfluencerInviteList(
      getInfluencerInviteListSuccess,
      getInfluencerInviteListFailed,
      updateProgress,
      params
    );
  }

  function getInfluencerInviteListSuccess(response_data) {
    setInfluencerInviteList(response_data.invite_list);
  }

  function getInfluencerInviteListFailed(errorList) {
    toast.error(errorList);
  }

  function postInfluencerInvite(influencer_id) {
    var params = {
      campaign_id: campaignId,
      influencer_id: influencer_id,
    };
    Core.postInfluencerInvite(
      postInfluencerInviteSuccess,
      postInfluencerInviteFailed,
      (status) => setInviteLoading({ [influencer_id]: status }),
      params
    );
  }

  function postInfluencerInviteSuccess(response_data) {
    toast.success("Invitation sent to Influencer");
    userSelfData();
    getInfluencerList(updateProgress, 10);
    getInfluencerInviteList(updateProgress);
  }

  function postInfluencerInviteFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }
  function postInfluencerInviteWithdraw(influencer_id) {
    var params = {
      campaign_id: campaignId,
      influencer_id: influencer_id,
    };
    Core.postInfluencerInviteWithdraw(
      postInfluencerInviteWithdrawSuccess,
      postInfluencerInviteWithdrawFailed,
      () => {},
      params
    );
  }

  function postInfluencerInviteWithdrawSuccess(response_data) {
    toast.success("Invite withdraw");

    getInfluencerList(updateProgress);
    getInfluencerInviteList(updateProgress);
  }

  function postInfluencerInviteWithdrawFailed(errorList) {
    toast.error(errorList);
  }

  function userSelfData() {
    var params = {
      // org_id: brand_deep_data.brand_data.org_id
    };

    Core.userSelfData(
      userSelfDataSuccess,
      userSelfDataFailed,
      () => {},
      params
    );
  }

  function userSelfDataSuccess(response_data) {
    //update brand user data in redux

    redux_dispatch({
      // type: Actions.UPDATE_ORG_DATA,
      // payload: { org_deep_data: response_data.org_data },
    });
  }

  function userSelfDataFailed(errorList) {}

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const openCampaignDetailsModal = () => {
    if (modalCampaignDetailsIsOpen == false) {
      setModalCampaignDetailsIsOpen(true);
    } else {
      setModalCampaignDetailsIsOpen(false);
    }
  };

  const closeCampaignDetailsModal = () => {
    setModalCampaignDetailsIsOpen(false);
  };

  function PublishedAndUnpublished(isActive) {
    const params = {
      is_active: isActive,
      campaign_id: campaignId,
    };
    Core.postActiveInactiveCampaign(
      postUnPublishedCampaignSuccess,
      postUnPublishedCampaignFailed,
      () => {},
      params
    );
  }
  function postUnPublishedCampaignSuccess() {
    getCampaignUserDetail(updateProgress_);
  }

  function postUnPublishedCampaignFailed(errorList) {
    // openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  const boostCampaign = (value) => {
    const params = { campaign_id: campaignId, is_boosted: value };

    Core.boostCampaign(
      (err, data) => {
        console.log("BOOST CAMPAIGN", data);
        toast.success(
          isBoosted === 0 ? "Boosted Successfully" : "Unboosted Successfully"
        );
        setIsBoosted(value);
        getCampaignUserDetail(updateProgress_); // Refresh campaign details
      },
      (err) => {
        toast.error("Boost failed");
        console.log(err);
        setLoading((prev) => ({ ...prev, boost: false })); // End loading
      },
      () => {
        setIsLoading((prev) => ({ ...prev, boost: false })); // End loading
      },
      params
    );
  };

  const publishCampaign = (value) => {
    const params = { campaign_id: campaignId };

    Core.publishCampaign(
      (err, data) => {
        getCampaignUserDetail(updateProgress_);
        toast.success("Campaign Published");
      },
      (err) => {
        toast.error("Publish failed");
        console.log(err);
        setLoading((prev) => ({ ...prev, publish: false })); // End loading
      },
      () => {
        setIsLoading((prev) => ({ ...prev, publish: false })); // End loading
      },
      params
    );
  };

  const OfflineOnlineCampaign = (value) => {
    const params = {
      campaign_id: campaignId,
      is_offline: value,
    };

    Core.getOfflineOnlineCampaign(
      (err, data) => {
        if (err) {
          toast.error("Operation failed");
          console.error(err);
        } else {
          console.log("ONLINE CAMP", data);
          toast.success(
            isOffline === 0
              ? "Campaign is now Offline"
              : "Campaign is now Online"
          );
          setIsOffline(value); // Update the offline state
          getCampaignUserDetail(updateProgress_); // Refresh campaign details
        }
      },
      (err) => {
        toast.error("Operation failed");
        console.log(err);
        setIsLoading((prev) => ({ ...prev, offline: false })); // End loading
      },
      () => {
        setIsLoading((prev) => ({ ...prev, offline: false })); // End loading
      },
      params
    );
  };
  const MarkComplete = () => {
    const params = {
      campaign_id: campaignId,
    };
    Core.postMarkComplete(
      postMarkCompleteSuccess,
      postMarkCompleteFailed,
      updateProgress,
      params
    );
  };
  function postMarkCompleteSuccess() {
    setIsComplete(true);
    closeModal();
    getCampaignUserDetail(updateProgress_);
  }
  function postMarkCompleteFailed(errorList) {
    toast.error(errorList);
  }
  const postMarkFreeCampaign = () => {
    const params = {
      campaign_id: campaignId,
    };
    Core.postMarkFreeCampaign(
      postMarkFreeCampaignSuccess,
      postMarkFreeCampaignFailed,
      updateProgress,
      params
    );
  };
  function postMarkFreeCampaignSuccess() {
    setIsComplete(true);
    closeModal();
    getCampaignUserDetail(updateProgress_);
  }
  function postMarkFreeCampaignFailed(errorList) {
    toast.error(errorList);
  }

  const openModalCampaignCompleted = () => {
    if (isActiveModal == "mark_completed") {
      MarkComplete();
    } else if (isActiveModal == "mark_free") {
      postMarkFreeCampaign();
    }
  };

  const openModal = () => {
    if (modalIsOpen == false) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  function renderVerifyModal() {
    return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={CancelReminder}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            background: "#ffffff",

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 54,
          }}
        >
          <div onClick={closeModal} className="close-btn-icon">
            <Icon iconName="CloseRoundButtonIcon" />
          </div>

          <CustomText
            p
            text={"Please Confirm"}
            textColor={"text_color_primary"}
            fontSize={"font_size_2xl"}
            className="typographyH7"
          />
        </div>
        <div className="create-assignment-container">
          <CustomText
            p
            fontSize={"font_size_medium"}
            textColor={"text_color_primary"}
            text={isActiveModalText}
            noOfLine="2"
            className={"two-line-text"}
          />
        </div>
        <div className="create-assignment-container">
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              paddingTop: 20,
            }}
          >
            <button
              style={{ color: "#090a14" }}
              className="confirm-btn-bid "
              onClick={closeModal}
            >
              Close
            </button>
            <div className=" m-l-32">
              <PrimaryCta
                fifth
                onClick={() => openModalCampaignCompleted()}
                fontSize={"font_size_large"}
                className="collection-create-btn"
                text={"Done"}
                isLoading={isLoading}
                //   disabled={!collectionId}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <div>
      <div className="dashboard-container">
        <SideMenu />
        <div className="main-screen">
          <Header title="Campaign Details" />

          <div className="internal-page-container">
            {isLoading ? (
              <div className="loader-mid">
                <Loader />
              </div>
            ) : (
              <>
                <div className="campaign-text-heading">
                  <CustomText
                    h5
                    text={"Campaign Details"}
                    className="typographyH7"
                  />

                  <div className="campaign-detail-edit-toggle-btn">
                    {campaignDetail?.campaign_data?.status !== "COMPLETED" &&
                      campaignDetail?.campaign_data?.status !== "IN_REVIEW" && (
                        <>
                          <CustomText
                            p
                            // textColor={"text_color_primary"}
                            fontSize={"font_size_extra_large"}
                            text={
                              campaignDetail?.campaign_data?.status === "ACTIVE"
                                ? "Inactive"
                                : "active"
                            }
                          />
                          <Toggle
                            className="custom-toggle"
                            checked={
                              campaignDetail?.campaign_data?.status === "ACTIVE"
                                ? true
                                : false
                            }
                            icons={false}
                            onChange={() =>
                              PublishedAndUnpublished(
                                campaignDetail?.campaign_data?.status ===
                                  "ACTIVE"
                                  ? 0
                                  : 1
                              )
                            }
                          />
                        </>
                      )}
                    {campaignDetail?.campaign_data?.status === "IN_REVIEW" && (
                      <PrimaryCta
                        sixth
                        textColor={"text_color_light"}
                        fontSize={"font_size_large"}
                        text={"Publish"}
                        className="unboost-campaigns"
                        onClick={() =>
                          publishCampaign(
                            campaignDetail.campaign_data.is_boosted == 0 ? 1 : 0
                          )
                        }
                      />
                    )}
                    {(campaignDetail?.campaign_data?.status === "ACTIVE" ||campaignDetail?.campaign_data?.status === "INACTIVE") && (
                      <PrimaryCta
                        sixth
                        textColor={"text_color_light"}
                        fontSize={"font_size_large"}
                        text={"Mark Complete"}
                        className="unboost-campaigns"
                        onClick={() => {
                          openModal();
                          setIsActiveModal("mark_completed");
                          setIsActiveModalText(
                            "Are you sure to mark this campaign COMPLETED. This action can not be undo,"
                          );
                        }}
                      />
                    )}
                    {campaignDetail?.campaign_data?.status === "ACTIVE" && (
                      <PrimaryCta
                        sixth
                        textColor={"text_color_light"}
                        fontSize={"font_size_large"}
                        text={
                          loading.boost
                            ? null
                            : campaignDetail.campaign_data.is_boosted == 0
                            ? "Boost"
                            : "Unboost"
                        }
                        className="unboost-campaigns"
                        onClick={() =>
                          boostCampaign(
                            campaignDetail.campaign_data.is_boosted == 0 ? 1 : 0
                          )
                        }
                      />
                    )}
                    {campaignDetail?.campaign_data?.status === "ACTIVE" && (
                      <PrimaryCta
                        sixth
                        textColor={"text_color_light"}
                        className="set-offline"
                        fontSize={"font_size_large"}
                        text={
                          loading.offline
                            ? null
                            : campaignDetail.campaign_data.is_offline == 0
                            ? "Set Offline"
                            : "Set Online"
                        }
                        onClick={() =>
                          OfflineOnlineCampaign(
                            campaignDetail.campaign_data.is_offline == 0 ? 1 : 0
                          )
                        }
                      />
                    )}
                    {campaignDetail?.campaign_data?.status === "ACTIVE" && (
                      <PrimaryCta
                        sixth
                        textColor={"text_color_light"}
                        className="set-offline"
                        fontSize={"font_size_large"}
                        text={"Mark Free"}
                        isLoading={isLoading}
                        onClick={() => {
                          setIsActiveModal("mark_free");
                          setIsActiveModalText(
                            "Are you sure to charge 0 credit for this campaign"
                          );

                          openModal();
                        }}
                      />
                    )}
                    {campaignDetail?.campaign_data?.status !== "COMPLETED" && (
                      <PrimaryCta
                        sixth
                        textColor={"text_color_light"}
                        iconName={"EditIcon"}
                        fontSize={"font_size_large"}
                        text={"Edit"}
                        onClick={() => openCampaignDetailsModal()}
                      />
                    )}
                  </div>
                </div>
                <div
                  className={
                    activeTab === "proposal-page"
                      ? "proposal-detail-container"
                      : "campaign-detail-container"
                  }
                >
                  <div className="campaign-detail-upper-container">
                    <div className="campaign-detail-tab-header">
                      <div
                        className={activeTab === "detail-page" ? "active" : ""}
                        onClick={() => handleTabClick("detail-page")}
                      >
                        <div className="campaign-detail-btn-tabs">
                          <CustomText
                            p
                            // textColor={"text_color_primary"}
                            fontSize={"font_size_extra_large"}
                            text={"Details"}
                          />
                        </div>
                      </div>

                      <div
                        className={activeTab === "invite-page" ? "active" : ""}
                        onClick={() => handleTabClick("invite-page")}
                      >
                        <div className="campaign-detail-btn-tabs">
                          <CustomText
                            p
                            fontSize={"font_size_extra_large"}
                            text={"Best Match"}
                          />
                        </div>
                      </div>
                      <div
                        className={
                          activeTab === "collection-page" ? "active" : ""
                        }
                        onClick={() => handleTabClick("collection-page")}
                      >
                        <div className="campaign-detail-btn-tabs">
                          <CustomText
                            p
                            // textColor={"text_color_primary"}
                            fontSize={"font_size_extra_large"}
                            text={"Shortlisted"}
                          />
                        </div>
                      </div>
                      <div
                        className={
                          activeTab === "proposal-page" ? "active" : ""
                        }
                        onClick={() => handleTabClick("proposal-page")}
                      >
                        <div className="campaign-detail-btn-tabs">
                          <CustomText
                            p
                            // textColor={"text_color_primary"}
                            fontSize={"font_size_extra_large"}
                            text={"Proposals"}
                          />
                        </div>
                      </div>

                      <div
                        className={
                          activeTab === "contracts-page" ? "active" : ""
                        }
                        onClick={() => handleTabClick("contracts-page")}
                      >
                        <div className="campaign-detail-btn-tabs">
                          <CustomText
                            p
                            // textColor={"text_color_primary"}
                            fontSize={"font_size_extra_large"}
                            text={"Contracts"}
                          />
                        </div>
                      </div>
                      <div
                        className={activeTab === "content-page" ? "active" : ""}
                        onClick={() => handleTabClick("content-page")}
                      >
                        <div className="campaign-detail-btn-tabs">
                          <CustomText
                            p
                            // textColor={"text_color_primary"}
                            fontSize={"font_size_extra_large"}
                            text={"Creatives"}
                          />
                        </div>
                      </div>

                      {campaignDetail?.campaign_data?.type !== "content" && (
                        <div
                          className={
                            activeTab === "analytics-page" ? "active" : ""
                          }
                          onClick={() => handleTabClick("analytics-page")}
                        >
                          <div className="campaign-detail-btn-tabs">
                            <CustomText
                              p
                              // textColor={"text_color_primary"}
                              fontSize={"font_size_extra_large"}
                              text={"Analytics"}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <CampaignDetailsScreen
                    isActive={activeTab === "detail-page"}
                    campaignDetail={campaignDetail}
                    campaignId={campaignId}
                    getCampaignUserDetail={getCampaignUserDetail}
                  />

                  <>
                    <InviteList
                      isActive={activeTab === "invite-page"}
                      campaignId={campaignId}
                      campaignDetail={campaignDetail}
                    />

                  </>

                  <BidListScreen
                    isActive={activeTab === "proposal-page"}
                    campaignId={campaignId}
                    campaignDetail={campaignDetail}
                    setBidListSort={setBidListSort}
                    bidListSort={bidListSort}
                    searchName={searchName}
                    setSearchName={setSearchName}
                    sortBy={sortBy}
                    handleUpdateCampaign={getCampaignUserDetail}
                    setSortBy={setSortBy}
                    sortOrder={sortOrder}
                    setSortOrder={setSortOrder}
                  />

                  <AssignmentListScreen
                    isActive={activeTab === "contracts-page"}
                    campaignId={campaignId}
                    campaignDetail={campaignDetail}
                  />

                  <ContentList
                    isActive={activeTab === "content-page"}
                    campaignId={campaignId}
                    campaignDetail={campaignDetail}
                  />

                  <AnalyticsCampaign
                    isActive={activeTab === "analytics-page"}
                    campaignId={campaignId}
                    campaignDetail={campaignDetail}
                    // brandId={brand_data.brand_id}
                    // orgId={brand_data.org_id}
                  />
                  <CollectionInfluencerCampaignList
                    isActive={activeTab === "collection-page"}
                    campaignId={campaignId}
                    campaignDetail={campaignDetail}
                    // brandId={brand_data.brand_id}
                    // orgId={brand_data.org_id}
                  />
                </div>
              </>
            )}
            {modalIsOpen && renderVerifyModal()}
            {modalCampaignDetailsIsOpen && (
              <EditCampaignDetailsScreen
                successCampaignDetail={getCampaignUserDetail}
                campaignId={campaignId}
                closeModal={closeCampaignDetailsModal}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CampaignDetailsParentScreen;
const CancelReminder = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "var(--general-secondary-color)",
    textAlign: "center",
    zIndex: "5",
    height: "20%",
    display: "flex",
    flexDirection: "column",
    minHeight: "200px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.95)",
    zIndex: 10,
  },
};
