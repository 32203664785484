import React from "react";
import { useState,useEffect } from "react";
import Core from "../../common/clientSdk";
import { toast } from "react-toastify";
import CustomTable from "../../components/customTable";
import { Loader } from "../loader";
import { useParams } from "react-router-dom";
import Utils from '../../common/utils';

const SubscriptionList=(props)=>{
  const [brandList,setBrandList]=useState([]);
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState(0);
  const [next, setNext] = useState(10);
  // const id=useParams().id;
  useEffect(()=>{
    if(props.id){
      getBrandListAdmin()
    } 
},[props.id])

function getBrandListAdmin(){
  var params = {
      // org_id:"knt6p0",
      org_id:props.id,
      // start: next,
    };
  Core.getBrandListAdmin(
    getBrandListAdminSuccess,
    getBrandListAdminFailed,
      (status)=>setLoading(status),
      params
  )
}
console.log("propppppppppppppppp",props.id,"knt6p0")
function getBrandListAdminSuccess(response){
  setBrandList(response.brand_list)
  setNext(response?.pagination_data?.next);
  console.log("Brandresponse",response)
  console.log()
}
console.log("brandList",brandList)
function getBrandListAdminFailed(errorList){
  toast.info(errorList.map((item) => item.m).join(", "));
}
  
const tableSchema = [
  {
    name: "Subscription ID",
    selector: (row) => row["brand_id"],
  },

  {
    name: "Duration",
    selector: (row) => {
      return Utils.convertUnixToDate(row.date_added);
    },

    maxWidth: "200px",
  },
  {
    name: "Start Date",
    selector: (row) => {
      return Utils.convertUnixToDate(row.date_added);
    },

    maxWidth: "200px",
  },
  {
    name: "End Date",
    selector: (row) => {
      return Utils.convertUnixToDate(row.date_added);
    },

    maxWidth: "200px",
  },
  {
    name: "Purchased On",
    selector: (row) => {
      return Utils.convertUnixToDate(row.date_added);
    },

    maxWidth: "200px",
  },
  
];

const tableData = brandList&&brandList?.map((e) => ({
  brand_id:e.brand_data.brand_id,
  org_id: e.brand_data.org_id,
  name:e.brand_data.name,
  date_added:e.brand_data.date_added,
}))

console.log("tableData",tableData)

const customStyles = {
  headRow: {
    style: {
      backgroundColor: "var(--general-secondary-color)",
      color: "var(--gray-gray-300)",
      fontSize: "16px",
      fontWeight: 600,
      lineHight: "19.2px",
      border: "none",
    },
  },
  headCells: {
    style: {
      padding: "10px 0px 10px 20px",
    },
  },
  rows: {
    style: {
      cursor: "pointer",
      fontSize: "16px",
      fontWeight: 400,
      lineHight: "19.2px",
      "&:hover": {
        backgroundColor: "var(--general-secondary-color)",
        fontWeight: 600,
      },
    },
  },
  cells: {
    style: {
      paddingLeft: "20px",
    },
  },
};

if(!props.isActive){
        return <></>
    }

    return(
  <div>
      {loading  ? (
              <Loader />
            ) : (
              <div className="custom-table">
                <CustomTable
                  className="CustomTable"
                  tableSchema={tableSchema}
                  tableData={tableData}
                  paginationPerPage={15}
                  rowPaddingTop={16}
                  rowPaddingBottom={16}
                  // progressPending={loading}
                  customStyles={customStyles}
                  // paginationServer
                  // paginationTotalRows={next}
                  // onChangeRowsPerPage={getTransactionHistoryList}
                  // onChangePage={handlePageChange}
                  pagination
                />
              </div>
            )}
  </div>
    )
}
export default SubscriptionList;