import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CustomCheckBoxCircular,
  CustomText,
  Image,
  PrimaryCta,
} from "../../components";
import SideMenu from "../../screens/sideMenu";
import { ModalComponent } from "../../components";
import Header from "../header";
import { Loader } from "../loader";
import Core from "../../common/clientSdk";
import { toast } from "react-toastify";
import OrganisationDetailsScreen from "./organisationDetailsScreen";
import OrganisationTransactionList from "./organisationTransactionList";
import OrganisationBrandList from "./organisationDetailsBrandList";
import SubscriptionList from "./subscriptionList";

const OrganisationParentDetails = () => {
  const [organisationDetail, setOrganisationDetail] = useState({});
  const [organisationData,setOrganisationData]=useState({})
  const [activeTab, setActiveTab] = useState("detail-page");
  const [isLoading, setLoading] = useState(false);
  const [banLoading, setBanLoading] = useState({ban:false});
  const [isBanLoading,setIsBanLoading]=useState(false)
  const [isBan,setIsBan]=useState(null)
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    adminGetDataSelf();
    getOrgDataUser();
  }, []);

  const id = useParams().id;

  console.log("useParams()", id);

  function getOrgDataUser(){
    var params = {
        org_id:id
      };
    Core.getOrgDataUser(
        getOrgDataUserSuccess,
        getOrgDataUsertFailed,
        ()=>{},
        params
    )
 }

 function getOrgDataUserSuccess(response){
    setOrganisationData(response?.org_data.org_data)
 }

 console.log("organisationData",organisationData)
  function getOrgDataUsertFailed(errorList){
    toast.info(errorList.map((item) => item.m).join(", "));
  }

  function adminGetDataSelf(progress_cb) {
    var params = {
    };
    Core.adminGetDataSelf(
      getOrgDataAdminSuccess,
      getOrgDataAdminFailed,
      () => {},
      progress_cb,
      params
    );
  }
  function getOrgDataAdminSuccess(response) {
    setOrganisationDetail(response);
  }
  function getOrgDataAdminFailed(errorList) {
    toast.info(errorList.map((item) => item.m).join(", "));
  }

  // console.log("organisationDetail0",organisationDetail)
  function updateProgress(status) {
    setLoading(status);
  }
  function updateProgressBan(status) {
    setIsBanLoading(status);
  }

  function openBanModal(){
    setOpenModal(!openModal)
  }
  function closeBanModal(){
    setOpenModal(!openModal)
  }
  
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const banUnbanOrg = (value) => {
    const params = {
      org_id: id,
      is_ban:value
    };

    Core.postBanOrg(
      banUnbanOrgSuccess,
      banUnbanOrgFailed,
      updateProgressBan,
      params
    );
  };

  function banUnbanOrgSuccess(value){
    toast.success(
      organisationData.ban === 0
        ? "Organisation is now Banned"
        : "Organisation is now UnBanned"
    );
    setIsBan(value);
    // setBanLoading(!banLoading)
    getOrgDataUser(updateProgress);
    closeBanModal();
    setIsBanLoading(false)
  }

  function banUnbanOrgFailed(errorList){
    toast.error(errorList.map((item) => item.m).join(", "));  
  }
  
  return (
    <div>
      <div className="dashboard-container">
        <SideMenu />
        <div className="main-screen">
          <Header />

          <div className="internal-page-container">
            {isLoading ? (
              <div className="loader-mid">
                <Loader />
              </div>
            ) : (
              <>
                <div className="campaign-text-heading">
                  <div>
                    <CustomText
                    h5
                    text={"Organisation Details"}
                    className="typographyH7"
                  />
                  </div>
                  <div>
                    <div className="" >
                    <PrimaryCta
                        sixth
                        textColor={"text_color_light"}
                        className="set-offline"
                        fontSize={"font_size_large"}
                        isLoading={isBanLoading}
                        text={organisationData.ban == 0
                          ? "Ban"
                          : "Unban"}
                        onClick={() =>
                          openBanModal() }
                      />
                   </div>
                  </div>
                </div>
                <div className={activeTab === "detail-page?"}>
                  <div className="campaign-detail-upper-container">
                    <div className="campaign-detail-tab-header">
                      <div
                        className={activeTab === "detail-page" ? "active" : ""}
                        onClick={() => handleTabClick("detail-page")}
                      >
                        <div className="campaign-detail-btn-tabs">
                          <CustomText
                            p
                            // textColor={"text_color_primary"}
                            fontSize={"font_size_extra_large"}
                            text={"Account Details"}
                          />
                        </div>
                      </div>
                      <div
                        className={
                          activeTab === "transaction-details-page"
                            ? "active"
                            : ""
                        }
                        onClick={() =>
                          handleTabClick("transaction-details-page")
                        }
                      >
                        <div className="campaign-detail-btn-tabs">
                          <CustomText
                            p
                            fontSize={"font_size_extra_large"}
                            text={"Wallet/Credit"}
                          />
                        </div>
                      </div>
                      <div
                        className={
                          activeTab === "brand-list-page" ? "active" : ""
                        }
                        onClick={() => handleTabClick("brand-list-page")}
                      >
                        <div className="campaign-detail-btn-tabs">
                          <CustomText
                            p
                            fontSize={"font_size_extra_large"}
                            text={"Brand List"}
                          />
                        </div>
                      </div>
                      <div
                        className={
                          activeTab === "subscription-list-page" ? "active" : ""
                        }
                        onClick={() => handleTabClick("subscription-list-page")}
                      >
                        <div className="campaign-detail-btn-tabs">
                          <CustomText
                            p
                            fontSize={"font_size_extra_large"}
                            text={"Subscription List"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <OrganisationDetailsScreen
                    isActive={activeTab === "detail-page"}
                    organisationDetail={organisationDetail}
                    // id={id}
                  />
                  <>
                    <OrganisationTransactionList
                      isActive={activeTab === "transaction-details-page"}
                      // isActive={true}
                    />
                  </>
                  <OrganisationBrandList
                    isActive={activeTab === "brand-list-page"}
                    id={id}
                  />
                  <SubscriptionList
                    isActive={activeTab === "subscription-list-page"}
                    organisationDetail={organisationDetail}
                    id={id}
                  />
                </div>
              </>
            )}
            {
            <ModalComponent
              isOpen={openModal}
              onRequestClose={() => closeBanModal()}
              customStyle={{
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  zIndex: 999999,
                },
                content: {
                  top: "50%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  // borderColor: "#f55879",
                  backgroundColor: "#F0F3F9",

                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.15)",
                },
              }}
            >
              <div className="">
                <CustomText
                  h3
                  text={`Do you want to ${ organisationData.ban == 0
                    ? "Ban This Organisation ?"
                    : "Unban This Organisation ?"}`}
                  textColor={"#040408"}
                  className="m-b-8 "
                />
              </div>
              <div
                style={{
                  marginTop: "20px",
                  display: "flex ",
                  justifyContent: "center",
                  gap: "30px",
                }}
              >
                <PrimaryCta
                  invert
                  className="back-btn-campaign"
                  onClick={() => closeBanModal()}
                  fontSize={"font_size_large"}
                  text={"No"}
                />
                <PrimaryCta
                  third
                  fontSize={"font_size_regular"}
                  className="header-credit-right"
                  text={"Yes"}
                  isLoading={isBanLoading}
                  onClick={() => banUnbanOrg(organisationData.ban == 0?1:0)}
                />
              </div>
            </ModalComponent>
          }
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrganisationParentDetails;
